import CheckAvailability from '@components/CheckAvailability';
import { logAction, setItem } from '@lib';
import { BOOKING_MODE } from 'config/constants';
import { ANALYTICS_TAGS, PAGENAMES } from 'config/datadog';
import { BOKING_MODE_STATE } from 'config/storage';
import useLocale from 'hooks/useLocale';
import React, { useEffect } from 'react';

const CreateBooking = () => {
  const trackingPageName = PAGENAMES.INDEX;
  const translations = useLocale();

  useEffect(() => {
    logAction(trackingPageName, ANALYTICS_TAGS.ON_PAGE_ENTER_CHECKAVAILABILITY);
  }, []);

  setItem(BOKING_MODE_STATE, BOOKING_MODE.CREATE);

  return (
    <>
      <CheckAvailability
        handleClick={() => {}}
        mode={BOOKING_MODE.CREATE}
        pageTitle={translations.booking.flightAndGuests.title}
        trackingPageName={trackingPageName}
      />
    </>
  );
};

export default CreateBooking;
